import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { db, doc, getDoc, setDoc } from "../firebase";

const Bauchbinde = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const view = queryParams.get("view"); // `form` oder `bauchbinde`

  const [formData, setFormData] = useState([{ name: "", position: "" }]); // Array für mehrere Personen
  const [bauchbindeData, setBauchbindeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Funktion zum Laden der Daten für die Bauchbinde
  const fetchData = async () => {
    try {
      const docRef = doc(db, "bauchbinde", "current");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data().people || [];
        setBauchbindeData(data);
        setFormData(data); // Setze den Formularstand auf die aktuellen Daten
      } else {
        console.error("Keine Daten für die Bauchbinde gefunden.");
        const defaultData = [
          {
            name: "Name nicht angegeben",
            position: "Position nicht angegeben",
          },
        ];
        setBauchbindeData(defaultData);
        setFormData(defaultData);
      }
    } catch (error) {
      console.error("Fehler beim Laden der Bauchbinde-Daten:", error);
    }
  };

  useEffect(() => {
    fetchData(); // Immer beim Rendern die Daten laden
  }, [view]);

  // Funktion zum Speichern der Daten für die Bauchbinde
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (formData.some((entry) => !entry.name || !entry.position)) {
      alert("Bitte fülle alle Felder aus.");
      return;
    }

    setIsLoading(true);
    try {
      const docRef = doc(db, "bauchbinde", "current");
      await setDoc(docRef, {
        people: formData,
        timestamp: new Date().toISOString(),
      });
      alert("Bauchbinde erfolgreich gespeichert!");
      navigate("?view=bauchbinde"); // Zur Bauchbindenansicht wechseln
    } catch (error) {
      console.error("Fehler beim Speichern der Bauchbinde:", error);
      alert("Speichern fehlgeschlagen. Bitte versuche es erneut.");
    } finally {
      setIsLoading(false);
    }
  };

  // Funktion zum Hinzufügen einer neuen Person
  const addPerson = () => {
    setFormData([...formData, { name: "", position: "" }]);
  };

  // Funktion zum Entfernen einer Person
  const removePerson = (index) => {
    setFormData(formData.filter((_, i) => i !== index));
  };

  if (view === "form") {
    // Formularansicht
    return (
      <div className="p-4 space-y-6">
        <h2 className="text-xl font-bold text-white">Bauchbinde erstellen</h2>
        <form onSubmit={handleFormSubmit} className="flex flex-col space-y-4">
          {formData.map((entry, index) => (
            <div key={index} className="flex flex-col space-y-2">
              <input
                type="text"
                placeholder="Name"
                value={entry.name}
                onChange={(e) =>
                  setFormData(
                    formData.map((item, i) =>
                      i === index ? { ...item, name: e.target.value } : item
                    )
                  )
                }
                className="p-2 rounded bg-gray-700 text-white"
              />
              <input
                type="text"
                placeholder="Position"
                value={entry.position}
                onChange={(e) =>
                  setFormData(
                    formData.map((item, i) =>
                      i === index ? { ...item, position: e.target.value } : item
                    )
                  )
                }
                className="p-2 rounded bg-gray-700 text-white"
              />
              <button
                type="button"
                onClick={() => removePerson(index)}
                className="text-red-500"
              >
                Entfernen
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addPerson}
            className="p-2 bg-green-500 rounded text-white"
          >
            Person hinzufügen
          </button>
          <button
            type="submit"
            className="p-2 bg-blue-500 rounded text-white"
            disabled={isLoading}
          >
            {isLoading ? "Speichern..." : "Speichern"}
          </button>
        </form>
        <button
          onClick={() => navigate("?view=bauchbinde")}
          className="p-2 bg-gray-500 rounded text-white"
        >
          Zur Bauchbinde wechseln
        </button>
      </div>
    );
  }

  // Bauchbindenansicht
  return (
    <div className="p-4 space-y-6">
      <div className="relative flex items-center justify-between w-full max-w-3xl p-6 bg-gradient-to-r from-slate-700 to-slate-900 text-white shadow-lg rounded-lg overflow-hidden pr-14">
        <div className="absolute top-0 left-0 bottom-0 w-2 bg-gradient-to-b from-yellow-500 to-pink-500"></div>

        {bauchbindeData.map((person, index) => (
          <div
            key={index}
            className="flex flex-col justify-center items-start relative"
          >
            {/* Name und Position */}
            <div className="flex flex-col pl-4">
              <span className="text-4xl font-extrabold leading-tight">
                {person.name}
              </span>
              <span className="text-xl text-yellow-400 font-medium">
                {person.position}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Bauchbinde;
