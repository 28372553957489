import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  query,
  where,
  getDoc,
  setDoc,
  doc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDu-qnc9tEWWNjgMVsoumNB0G3Xlr_k2OI",
  authDomain: "fcfg-dartsgala.firebaseapp.com",
  projectId: "fcfg-dartsgala",
  storageBucket: "fcfg-dartsgala.appspot.com",
  messagingSenderId: "237262055108",
  appId: "1:237262055108:web:173c511166f03c0a37cb91",
  measurementId: "G-G2KKQ9C3PH",
  databaseURL:
    "https://fcfg-dartsgala-default-rtdb.europe-west1.firebasedatabase.app/",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const realtimeDb = getDatabase(app); // Realtime Database
const auth = getAuth(app);

export {
  db,
  auth,
  realtimeDb,
  collection,
  addDoc,
  getDocs,
  where,
  query,
  doc,
  getDoc,
  setDoc,
};
