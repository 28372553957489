import React, { useState, useEffect } from "react";
import { Typewriter } from "react-simple-typewriter";

const HeroSection = ({ eventStartDate, scrollToSection }) => {
  const [timeLeft, setTimeLeft] = useState({
    tage: 0,
    stunden: 0,
    minuten: 0,
    sekunden: 0,
  });

  // Countdown-Berechnung
  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = eventStartDate - now;

      if (difference > 0) {
        setTimeLeft({
          tage: Math.floor(difference / (1000 * 60 * 60 * 24)),
          stunden: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minuten: Math.floor((difference / (1000 * 60)) % 60),
          sekunden: Math.floor((difference / 1000) % 60),
        });
      } else {
        setTimeLeft({
          tage: 0,
          stunden: 0,
          minuten: 0,
          sekunden: 0,
        });
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, [eventStartDate]);

  return (
    <section
      className="relative h-screen w-full flex items-center justify-center bg-cover bg-center text-center"
      style={{
        backgroundImage: `url('/images/_2500337.JPG')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
      }}
    >
      {/* Verdunkeltes Overlay für das Hintergrundbild */}
      <div className="absolute inset-0 bg-black opacity-60"></div>

      {/* Inhalt in einem zentrierten Container */}
      <div className="relative z-10 flex flex-col items-center text-white w-full max-w-6xl px-4">
        {/* Logo */}
        <img
          src="/images/Logo_b_with_w_arrow.png"
          alt="FC Freier Grund Darts Gala"
          className="w-64 md:w-80 lg:w-96 mb-8 object-contain"
        />

        {/* Countdown-Timer mit responsive Größen */}
        <div className="flex space-x-4 mb-12 text-center">
          {Object.entries(timeLeft).map(([label, value]) => (
            <div key={label} className="flex flex-col items-center">
              <p className="text-3xl md:text-5xl font-bold bg-white text-gray-900 rounded-lg px-4 md:px-6 py-2 shadow-md tracking-wider">
                {value}
              </p>
              <p className="text-sm md:text-lg text-white mt-2">
                {label.charAt(0).toUpperCase() + label.slice(1)}
              </p>
            </div>
          ))}
        </div>

        {/* Buttons */}
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6">
          {/* <button
            onClick={() => scrollToSection("eventDescriptionSection")}
            className="bg-gradient-to-r from-gray-700 to-gray-800 hover:from-gray-600 hover:to-gray-700 text-white font-bold py-3 px-10 rounded-full transition duration-300 shadow-md tracking-wider"
          >
            Tickets
          </button> */}
          <a
            className="bg-gradient-to-r font-extrabold from-gray-700 to-gray-800 hover:from-gray-600 hover:to-gray-700 text-white font-bold py-3 px-10 rounded-full transition duration-300 shadow-md tracking-wider"
            href="https://live.fcfg-dartsgala.de"
          >
            LIVE
          </a>
          {/* <button
            onClick={() => scrollToSection("playerRegistrationSection")}
            className="bg-gradient-to-r from-gray-700 to-gray-800 hover:from-gray-600 hover:to-gray-700 text-white font-bold py-3 px-10 rounded-full transition duration-300 shadow-md tracking-wider"
          >
            Anmeldung
          </button> */}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
