import React from "react";

const sponsors = [
  {
    id: 1,
    name: "Westenergie",
    image: "/images/sponsoren/WestEnergie.png",
    link: "https://westenergie.de",
  },
  {
    id: 2,
    name: "MyDearCaptain",
    image: "/images/sponsoren/MyDearCaptain.png",
    link: "https://www.mydearcaptain.com/",
  },
  {
    id: 3,
    name: "Sparkasse",
    image: "/images/sponsoren/Sparkasse.png",
    link: "https://www.sparkasse-burbach-neunkirchen.de/",
  },
  {
    id: 4,
    name: "MDPrint",
    image: "/images/sponsoren/MDPrint.png",
    link: "https://mdprint-company.de/de/startseite.html",
  },
  {
    id: 5,
    name: "RobinSohn",
    image: "/images/sponsoren/RobinSohn.png",
    link: "https://www.robinsohn.de/",
  },
  {
    id: 6,
    name: "Marxmeier",
    image: "/images/sponsoren/Marxmeier.png",
    link: "https://www.praxis-marxmeier.de/",
  },
  {
    id: 7,
    name: "Persoplan",
    image: "/images/sponsoren/PersoPlan.png",
    link: "https://persoplan.de/standorte/siegen?searchTitle=&searchLocation=&searchRadius=5",
  },
  {
    id: 7,
    name: "McDart",
    image: "/images/sponsoren/MCDart.png",
    link: "https://mcdart.de/",
  },
  {
    id: 8,
    name: "HO3",
    image: "/images/sponsoren/ho3.png",
    link: "https://www.hodrei.de/",
  },
  {
    id: 9,
    name: "Krombacher",
    image: "/images/sponsoren/Krombacher.png",
    link: "https://www.krombacher.de/",
  },
  {
    id: 9,
    name: "Heiko Paul",
    image: "/images/sponsoren/HeikoPaul.png",
    link: "",
  },
  // Weitere Sponsoren hinzufügen
];

const SponsorsSection = () => (
  <section className="w-full max-w-6xl mx-auto my-6 p-8 bg-gray-800 text-center rounded-lg">
    <h2 className="text-4xl font-semibold mb-4">Unsere Sponsoren</h2>
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6">
      {sponsors.map((sponsor) => (
        <a
          key={sponsor.id}
          href={sponsor.link}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-gray-700 p-2 rounded-lg shadow-lg flex items-center justify-center transition-transform transform hover:scale-105"
        >
          <img
            src={sponsor.image}
            alt={`${sponsor.name} Logo`}
            className="w-full h-full object-contain"
          />
        </a>
      ))}
    </div>
  </section>
);

export default SponsorsSection;
